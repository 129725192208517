import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "DNS Services",
  sectionHeader: "goSystem takes the hassle out of DNS management",
  sectionContent:
    "goSystem's cloud services incorporate both physical and off-site computing to make the best use of cloud infrastructure. Striking the perfect balance between cloud and physical to ensure your solution is cost-effective, secure, and reliable. If your needs suit it, a fully cloud-based solution is possible, allowing you to have the features of an on-site solution, with the benefits of the cloud.\n\nWith goSystem Distributed Cloud you can guarantee access to the critical services your business requires to succeed, anywhere, anytime.",
  splitTag: "Cloud that fits your needs",
  splitHeading: "We make the cloud work for you without compromise.",
  splitContent:
    "Our combination of on-site and cloud solutions ensure you get the most out of your existing infrastructure and are ready for the future of distributed cloud computing and infrastructure.\n\nOur cloud solutions ensure you, your employees and your customers always have fast, reliable and secure access to the services they need.",
  callToAction: "Manage DNS with rapid propagation with goSystem",
};

export default () => ServicesTemplate(config);
